import React, { useContext, useEffect, useState } from 'react'
import { Gen3Context } from '../contexts/Gen3Context'
import { Web3Context } from '../contexts/Web3Context'
import { navigate } from 'gatsby'
import Modal from './modal'
import OwnedBearsGen2, { ImageAttributes } from './owned-bears-gen2'
import * as styles from './mate-gen2.module.css'

export default function MateGen2() {

	const { contract, mateBears } = useContext(Gen3Context) || {}
	const { account, chainId } = useContext(Web3Context) || {}

	const [controlsDisabled, setControlsDisabled] = useState(false)
	const [selectedBearA, setSelectedBearA] = useState<ImageAttributes|null>(null)
	const [selectedBearB, setSelectedBearB] = useState<ImageAttributes|null>(null)
	const [selectDisabled, setSelectDisabled] = useState(false)
	const [showModalA, setShowModalA] = useState(false)
	const [showModalB, setShowModalB] = useState(false)

	function closeModal() {
		setShowModalA(false)
		setShowModalB(false)
	}

	async function mateClicked() {
		setControlsDisabled(true)
		try {
			await mateBears(selectedBearA.tokenId, selectedBearB.tokenId)
			switch (selectedBearA.species) {
				case 0: // Brown
					navigate("/06923488/", { replace: true })
					break
				case 1: // Black
					navigate("/39626987/", { replace: true })
					break
				case 2: // Polar
					navigate("/83248428/", { replace: true })
					break
				case 3: // Panda
					navigate("/94490237/", { replace: true })
					break
			}
		} catch (error) {
			// TODO: Notify the user with a Toast message
			if (error.code == 4001 || error.code == 4100) {
				console.log(`Adoption not authorized by user`)
			} else {
				console.error(error)
			}
		}
		setControlsDisabled(false)
	}

	function startSelectionA() {
		setShowModalA(true)
	}

	function startSelectionB() {
		setShowModalB(true)
	}

	function completeSelectionA(selectedBear: ImageAttributes) {
		console.log(`Selected bear a ${selectedBear.tokenId}`)
		setSelectedBearA(selectedBear)
		setSelectedBearB(null)
		setShowModalA(false)
	}

	function completeSelectionB(selectedBear: ImageAttributes) {
		console.log(`Selected bear b ${selectedBear.tokenId}`)
		setSelectedBearB(selectedBear)
		setShowModalB(false)
	}

	function contractsUnavailable() {
		return contract === null || contract === undefined
	}

	useEffect(() => {
		setSelectDisabled(contractsUnavailable())
	}, [contract])

	useEffect(() => {
		setControlsDisabled(contractsUnavailable() || selectedBearA === null || selectedBearB === null)
	}, [contract, selectedBearA, selectedBearB])

	useEffect(() => {
		setSelectedBearA(null)
		setSelectedBearB(null)
	}, [account, chainId])

	return (
		<>
			<div className={styles.section}>
				<ul>
					<li onClick={selectDisabled ? null : startSelectionA}>
						<img src={selectedBearA?.image ?? "/img/gen-2-bear-outline.png"} alt="Bear"/>
						{selectedBearA == null ?
							<p>Select Bear A</p> : <p className="selected">{selectedBearA.name}</p>
						}
					</li>
				</ul>
				<ul>
					<li onClick={selectDisabled ? null : startSelectionB}>
						<img src={selectedBearB?.image ?? "/img/gen-2-bear-outline.png"} alt="Bear"/>
						{selectedBearB == null ?
							<p>Select Bear B</p> : <p className="selected">{selectedBearB.name}</p>
						}
					</li>
				</ul>
			</div>
			<button className="initiate" disabled={controlsDisabled} onClick={mateClicked}><h4>Initiate Cloud Breeding Process</h4></button>
			{showModalA ? <Modal title="Select Bear A" onUserClose={closeModal}>
				<OwnedBearsGen2 onUserSelection={completeSelectionA}/>
			</Modal> : <></>}
			{showModalB ? <Modal title="Select Bear B" onUserClose={closeModal}>
				<OwnedBearsGen2 onUserSelection={completeSelectionB} existingSelection={selectedBearA}/>
			</Modal> : <></>}
		</>
	)
}
