import React, { createContext, useContext, useEffect, useState } from 'react'
import { Web3Context } from './Web3Context'
import { useStaticQuery, graphql } from 'gatsby'

export const Gen3Context = createContext(null)

export const Gen3Provider = ({ children }) => {

	const { account, chainId, web3 } = useContext(Web3Context) || {}
	const [contract, _setContract] = useState(null)

	async function setContract(_contract: any) {
		if (_contract !== null) {
			// let max = await _contract.methods.maximumDonation().call()
			// let min = await _contract.methods.minimumDonation().call()
			// setMaxDonation(web3.utils.fromWei(max.toString(), "ether"))
			// setMinDonation(web3.utils.fromWei(min.toString(), "ether"))
		}
		_setContract(_contract)
	}

	const data = useStaticQuery(graphql`
		query {
			allFile(filter: { sourceInstanceName: { eq: "contract-data" }, name: { eq: "TwoBitBears3" } }) {
				nodes {
					relativeDirectory
					name
					fields { contents }
				}
			}
		}
	`)

	async function checkBreedingStatus(tokenId: number): Promise<boolean> {
		return await contract.methods.hasGen2Mated(tokenId).call()
	}

	async function mateBears(firstParentId: number, secondParentId: number) {
		// First use the mating gas for estimates
		var gasLimit: string
		try {
			gasLimit = await contract.methods.matingGas(account, firstParentId, secondParentId).call()
			if (parseInt(`${gasLimit}`) >= 305000) {
				gasLimit = "335000"
			}
		} catch (error) {
			console.error(error)
			gasLimit = "335000" // This is the fallback gas limit which works for all transactions
		}
		var parameters = { gasLimit: gasLimit, from: account, type: "0x2" }
		console.log(`Mating Gen 2 Bears ${firstParentId} & ${secondParentId}! Wait for transaction and auto-refresh`)
		await contract.methods.mateBears(firstParentId, secondParentId).send(parameters)
	}

	async function loadContract() {
		if (chainId === null || chainId == 0 || web3 === null || web3 === undefined || web3.eth === null || web3.eth === undefined) {
			setContract(null)
			return
		}
		try {
			for (const node of data.allFile.nodes) {
				if (parseInt(node.relativeDirectory) == chainId) {
					const json = JSON.parse(node.fields.contents)
					setContract(new web3.eth.Contract(json.abi, json.address))
					return
				}
			}
			console.warn(`Could not find TwoBitBears3 contract for chain ${chainId}`)
			setContract(null)
		} catch (error) {
			console.error(error)
			setContract(null)
		}
	}

	useEffect(() => {
		loadContract()
	}, [chainId, web3])

	return (
		<Gen3Context.Provider value={{contract, checkBreedingStatus, mateBears}}>
			{children}
		</Gen3Context.Provider>
	)
}
