import Layout from '../components/layout'
import React from 'react'
import { Link } from 'gatsby'
import { Gen2Provider } from '../contexts/Gen2Context'
import { Gen3Provider } from '../contexts/Gen3Context'
import MateGen2 from '../components/mate-gen2'

export default function IndexPage({ data }) {

	return (
		<Layout prompt="Select The Gen 2 Bears That You Wish To Breed">
			<p className="alert">Gen 2 Bears must be fully grown in order to breed. <Link to="/grow/">Grow your Gen 2 Cubs here.</Link></p>
			<p className="alert">Each Gen 2 Bear can only breed once. <Link to="/gen-2-parent-inventory/">Check your Gen 2 Bear Breeding Status here.</Link></p>
			<p className="red">The Mood of Bear A will be the Mood of your Gen 3 Bear</p>
			<Gen2Provider>
				<Gen3Provider>
					<MateGen2/>
				</Gen3Provider>
			</Gen2Provider>
		</Layout>
	)
}
